export default {
	methods: {
		parsedJSON (json) {
			try {
				return JSON.parse(json)
			} catch (e) {
				return {}
			}
		}
	}
}
