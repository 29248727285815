<template>
	<div>
		<SectionCard
			:background="background"
			:flat="flat"
		>
			<template #title>
				{{ cardTitle | useLabels }}
			</template>
			<template #title-action>
				<TextButton
					v-if="!readOnly"
					:test-id="fieldId('add_metadata')"
					@click="showMetaDataModal = true"
				>
					<v-icon>
						mdi-plus
					</v-icon>
					Add Metadata
				</TextButton>
			</template>
			<template #body>
				<DataTable
					:items="assignedMetaData"
					:headers="tableHeaders"
				>
					<template #item.action="{ item }">
						<v-icon
							v-if="!readOnly"
							small
							class="mr-2"
							:data-testid="fieldId(`edit_metadata_${item.metaDataKeyId}_${item.metaDataValue}`)"
							@click="editMetadata(item)"
						>
							mdi-pencil
						</v-icon>
						<v-icon
							v-if="!readOnly"
							small
							:data-testid="fieldId(`delete_metadata_${item.metaDataKeyId}_${item.metaDataValue}`)"
							@click="removeMetadata(item)"
						>
							mdi-delete
						</v-icon>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<MetadataModal
			v-if="showMetaDataModal"
			:module-type="moduleType"
			:assigned-meta-data="assignedMetaData"
			:meta-data-to-edit="metaDataToEdit"
			:component-type-id="componentTypeId"
			@update:metadata="updateMetadata"
			@close="() => {
				showMetaDataModal = false,
				metaDataToEdit = null
			}"
		/>
	</div>
</template>

<script>
import SectionCard from '../../../../shared/components/section-card.vue'
import DataTable from '../../../../shared/components/data-table.vue'
import TextButton from '../../../../shared/components/text-button.vue'
import MetadataModal from './metadata-modal.vue'

export default {
	components: {
		SectionCard,
		DataTable,
		TextButton,
		MetadataModal
	},
	props: {
		moduleType: {
			type: String,
			required: true
		},
		assignedMetaData: Array,
		readOnly: Boolean,
		componentTypeId: Number,
		background: Boolean,
		flat: Boolean
	},
	data () {
		return {
			showMetaDataModal: false,
			metaDataToEdit: null
		}
	},
	computed: {
		cardTitle () {
			return `${this.moduleType} Metadata`
		},
		tableHeaders () {
			const headers = [
				{
					value: 'metaDataKeyName',
					text: 'Metadata Key',
					width: '40%'
				},
				{
					value: 'metaDataValue',
					text: 'Metadata Value',
					width: '40%'
				}
			]
			if (!this.readOnly) {
				headers.push(
					{
						value: 'action',
						text: 'Action',
						align: 'right',
						width: '20%'
					}
				)
			}
			return headers
		}
	},
	methods: {
		fieldId (field) {
			return `${this.moduleType.toLowerCase().replaceAll(' ', '_')}_${field}`
		},
		updateMetadata (metadata) {
			this.$emit('update:metadata', metadata)
		},
		editMetadata (metadata) {
			this.metaDataToEdit = metadata
			this.showMetaDataModal = true
		},
		removeMetadata (item) {
			const index = this.assignedMetaData.findIndex(
				metadata => metadata.metaDataKeyId === item.metaDataKeyId && metadata.metaDataValue === item.metaDataValue)
			const assignedMetaData = this.assignedMetaData
			assignedMetaData.splice(index, 1)
			this.updateMetadata(assignedMetaData)
		}
	}
}
</script>
