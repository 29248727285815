<template>
	<validation-provider
		#default="{ errors }"
		:name="fieldName || $props.label"
		:rules="rules"
		:mode="validationMode"
		:custom-messages="customMessages"
		tag="div"
		:class="tooltipText || reserveTooltipSpace ? 'd-flex flex-row cassie-horizontal-sm align-baseline' : ''"
	>
		<v-textarea
			v-bind="$props"
			:readonly="viewOnly"
			:persistent-placeholder="viewOnly"
			:error-messages="errors.length ? [errors[0]] : []"
			:class="getQaSelectorClass($props)"
			:rules="[]"
			outlined
			:hide-details="!errors.length"
			:data-testid="testId"
			v-on="$listeners"
		/>
		<v-tooltip
			v-if="tooltipText"
			top
		>
			<template #activator="{ on }">
				<v-icon
					light
					v-on="on"
				>
					mdi-information
				</v-icon>
			</template>
			<span>
				{{ tooltipText }}
			</span>
		</v-tooltip>
		<div
			v-if="reserveTooltipSpace"
			style="width: 24px;"
		/>
	</validation-provider>
</template>

<script>
import { VTextarea } from 'vuetify/lib'
import FieldValidationMixin from '../validation/field-validation-mixin.js'

export default {
	name: 'text-area',
	extends: VTextarea,
	mixins: [FieldValidationMixin],
	props: {
		tooltipText: {
			type: String
		},
		viewOnly: {
			type: Boolean,
			default: false
		},
		testId: {
			type: String,
			default: 'cassie_textarea'
		},
		reserveTooltipSpace: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		getQaSelectorClass (props) {
			let trimmedLabel = props.label
			if (trimmedLabel) {
				if (trimmedLabel.includes(' *')) {
					trimmedLabel = trimmedLabel.replace(' *', '')
				}
				trimmedLabel = trimmedLabel.replace(/\s/g, '-').toLowerCase()
			}
			return `qa-textarea-${trimmedLabel}`
		}
	}
}
</script>
