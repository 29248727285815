<template>
	<div class="d-flex">
		<slot name="actions" />
	</div>
</template>
<script>
export default {
	name: 'page-action-row'
}
</script>
