<template>
	<div class="d-flex flex-column cassie-vertical-md">
		<div class="d-flex cassie-horizontal-sm">
			<TextField
				v-if="isText"
				v-model="selection"
				:label="`Add ${entityType}`"
				:rules="textRules"
				:custom-messages="textCustomMessages"
				style="flex: auto;"
				:test-id="testId"
				:disabled="disabled"
			/>
			<Dropdown
				v-else
				v-model="selection"
				:items="filteredOptions"
				:label="dropdownLabel"
				:rules="required ? { arrayIsNotEmpty: { value: selected, ignoreVModel: true } } : {}"
				:custom-messages="{ arrayIsNotEmpty: `You must add at least one ${entityType}` }"
				validation-mode="passive"
				style="flex: auto;"
				:test-id="testId"
				:disabled="disabled"
			/>
			<PrimaryActionButton
				:disabled="disableAddButton"
				:test-id="testId ? `${testId}_add` : undefined"
				@click="onAdd"
			>
				Add
			</PrimaryActionButton>
		</div>
		<v-card
			v-if="!selected.length"
			flat
			style="border: 1px solid currentColor !important; height: 100px;"
			class="background pa-4 d-flex align-center justify-center"
		>
			<span>
				No {{ entityType }}(s) have been added to this list yet
			</span>
		</v-card>
		<v-simple-table
			v-else
			dense
		>
			<template #default>
				<thead>
					<tr>
						<th
							class="text-left"
							scope="col"
							style="width: 80%;"
						>
							{{ entityType }}
						</th>
						<th
							v-if="!disabled"
							class="text-left"
							scope="col"
							style="width: 80px;"
						>
							Action
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="value in selected"
						:key="value"
					>
						<td class="text-left">
							{{ getText(value) }}
						</td>
						<td class="text-left">
							<v-icon
								v-if="!disabled"
								small
								:data-testid="testId ? `${testId}_remove_${value}` : undefined"
								@click="onRemove(value)"
							>
								mdi-trash-can
							</v-icon>
						</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
	</div>
</template>

<script>
import Dropdown from './dropdown.vue'
import PrimaryActionButton from './primary-action-button.vue'
import TextField from './text-field.vue'
export default {
	components: { TextField, PrimaryActionButton, Dropdown },
	props: {
		options: [Array, String],
		dropdownLabel: {
			type: String,
			default: 'Included'
		},
		entityType: {
			type: String,
			default: 'Item'
		},
		selected: {
			type: Array,
			default: () => []
		},
		required: Boolean,
		rules: Object,
		isText: Boolean,
		disabled: Boolean,
		testId: String
	},
	data () {
		return {
			selection: null
		}
	},
	computed: {
		filteredOptions () {
			return this.options.filter(option => !this.selected.includes(option.value))
		},
		textRules () {
			if (this.required) return { required: this.selected.length === 0 }
			else return this.rules || {}
		},
		textCustomMessages () {
			if (this.required) return { required: `You must add at least one ${this.entityType}` }
			else return {}
		},
		disableAddButton () {
			if (this.rules?.isUrlwithHttp) {
				return [undefined, '', null].includes(this.selection) || this.disabled || !(/^(http:\/\/|https:\/\/)(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/).test(this.selection)
			} else {
				return [undefined, '', null].includes(this.selection) || this.disabled
			}
		}
	},
	methods: {
		onRemove (value) {
			this.updateSelected(this.selected.filter(s => s !== value))
		},
		onAdd () {
			this.updateSelected([...this.selected, this.selection])
			this.selection = null
		},
		updateSelected (selected) {
			this.$emit('update:selected', selected)
		},
		getText (value) {
			if (this.isText) return value
			return this.options.find(option => option.value === value)?.text
		}
		// textRules () {
		// 	if (this.required) return { required: this.selected.length === 0 }
		// 	else return this.rules || {}
		// },
	}
}
</script>
