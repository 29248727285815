<template>
	<v-btn
		v-bind="$props"
		text
		:color="color"
		:class="getQaSelectorClass()"
		class="text-capitalize"
		:data-testid="testId"
		v-on="$listeners"
	>
		<slot />
	</v-btn>
</template>

<script>
import { VBtn } from 'vuetify/lib'

export default {
	name: 'text-button',
	extends: VBtn,
	props: {
		color: {
			type: String,
			default: 'blue darken-2'
		},
		testId: {
			type: String,
			default: 'cassie_textbutton'
		}
	},
	methods: {
		getQaSelectorClass () {
			const buttonText = this.$slots.default.find(({ text }) => text).text.trim().replace(/\s/g, '-').toLowerCase()
			return `qa-textbutton-${buttonText}`
		}
	}
}
</script>
