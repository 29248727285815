import { format } from 'date-fns'
import { DATE_FORMAT } from '../date-formatting.js'
import { configApi } from '../http-client.js'

export const getConsentBannerAccessKeys = () => configApi.get('/api/AccessKeys/ConsentBanner').then(({ data }) => ({
	cookieAccessKeys: data.map(accessKey => ({
		...accessKey,
		name: accessKey.accessKeyName,
		createdDate: format(new Date(accessKey.createdDate), DATE_FORMAT)
	}))
}))
export const getPreferenceWidgetAccessKeys = () => configApi.get('/api/AccessKeys/PreferenceWidget').then(({ data }) => ({
	preferenceWidgetAccessKeys: data.map(accessKey => ({
		...accessKey,
		name: accessKey.accessKeyName,
		createdDate: format(new Date(accessKey.createdDate), DATE_FORMAT)
	}))
}))
export const getApiKeys = () => configApi.get('api/ApiKeys').then(({ data }) => ({
	apiKeys: data.map(apiKey => ({
		...apiKey,
		accessKey: apiKey.apiKey,
		brandId: apiKey.brandIds[0],
		createdDate: format(new Date(apiKey.createdDate), DATE_FORMAT)
	}))
}))
export const getAllEndpoints = () => configApi.get('api/ApiKeys/Endpoints')

export const postCookieAccessKey = async accessKey => await configApi.post('/api/AccessKeys/ConsentBanner', accessKey)
export const postPreferenceWidgetAccessKey = async accessKey => await configApi.post('/api/AccessKeys/PreferenceWidget', accessKey)
export const putCookieAccessKey = async accessKey => await configApi.put('/api/AccessKeys/ConsentBanner', accessKey)
export const putPreferenceWidgetAccessKey = async accessKey => await configApi.put('/api/AccessKeys/PreferenceWidget', accessKey)

export const upsertApiKey = async apiKey => await configApi.post('api/ApiKeys/Upsert', apiKey)
export const createApiKey = async apiKey => await configApi.post('api/ApiKeys', apiKey)
export const updateApiKey = async apiKey => await configApi.put('api/ApiKeys', apiKey)

export const deleteCookieAccessKey = async cookieAccessKey => await configApi.delete(`/api/AccessKeys/ConsentBanner/${cookieAccessKey}`)
export const deletePreferenceWidgetAccessKey = async preferenceWidgetAccessKey => await configApi.delete(`/api/AccessKeys/PreferenceWidget/${preferenceWidgetAccessKey}`)
export const deleteApiKey = async apiKey => await configApi.delete(`/api/ApiKeys/${apiKey}`)
