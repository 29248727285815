<template>
	<SectionCard>
		<template #title>
			Privacy Policy
		</template>
		<template #title-action>
			<Toggle
				class="mr-2"
				:disabled="!userFullPermissions"
				:value.sync="customPrivacyPolicyEnabled"
				:test-id="`${type}_enable_privacy_policy`"
				label-left
				label="Enabled"
				@update:value="updatePrivacyPoliciesEnabled()"
			/>
		</template>
		<template #body>
			<p>
				Enabling this option will allow you to specify Privacy Policy and Version for submissions made using this link depending on the language that it is being displayed to the user in.
			</p>
			<template v-if="customPrivacyPolicyEnabled">
				<div class="cassie-vertical-md">
					<div class="cassie-horizontal-md d-flex flex-row">
						<Dropdown
							v-model="selectedLanguageId"
							label="Language*"
							class="cassie-input-width-md"
							:items="filteredAssignedLanguages"
							:test-id="`${type}_privacy_policy_language`"
							:disabled="!userFullPermissions"
						/>
						<Dropdown
							v-model="selectedPrivacyPolicy"
							label="Privacy Policy*"
							:items="brandFilteredActivePrivacyPolicies"
							custom-sort
							:disabled="(!brandId && brandId !== 0) || !userFullPermissions || filteredAssignedLanguages.length === 0"
							return-object
							class="cassie-input-width-md"
							:test-id="`${type}_privacy_policy`"
							@input="getLatestVersion()"
						/>
					</div>
					<div class="cassie-horizontal-md d-flex flex-row">
						<Dropdown
							v-if="selectedPrivacyPolicy"
							v-model="selectedPrivacyPolicyVersion"
							:items="versionOptions"
							:test-id="`${type}_privacy_policy_version`"
							:disabled="!userFullPermissions || filteredAssignedLanguages.length === 0"
							return-object
							label="Version*"
							class="cassie-input-width-md"
						/>
						<PrimaryActionButton
							:disabled="!userFullPermissions || filteredAssignedLanguages.length === 0 || !allowAddPrivacyPolicy"
							:test-id="`${type}_add_privacy_policy`"
							@click="addPrivacyPolicy"
						>
							Add
						</PrimaryActionButton>
					</div>
					<DataTable
						:headers="tableHeaders"
						:items="customPrivacyPolicies"
						hide-default-footer
					>
						<template #item.languageName="{ item }">
							{{ displayLanguageName(item.languageId) }}
						</template>
						<template #item.privacyPolicyName="{ item }">
							{{ displayPrivacyPolicyName(item.privacyPolicyId) }}
						</template>
						<template #item.privacyPolicyVersionId="{ item }">
							{{ item.privacyPolicyVersionId === null ? 'Always Use Latest Version' : item.privacyPolicyVersionId }}
						</template>
						<template #item.action="{ item, index }">
							<v-icon
								v-if="userFullPermissions"
								small
								:data-testid="`${type}_delete_privacy_policy_${item.privacyPolicyId}`"
								@click="removeCustomPrivacyPolicy(index)"
							>
								mdi-trash-can
							</v-icon>
						</template>
					</DataTable>
				</div>
			</template>
		</template>
	</SectionCard>
</template>

<script>

import SectionCard from '../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import Toggle from '../../../../../shared/components/toggle.vue'
import { activePrivacyPolicies, privacyPolicies } from '../../../../../shared/state/privacy-policies.js'
const UNIVERSAL_BRAND = 0

export default {
	components: { SectionCard, Dropdown, PrimaryActionButton, DataTable, Toggle },
	props: {
		customPrivacyPolicies: Array,
		userFullPermissions: Boolean,
		brandId: Number,
		assignedLanguages: Array,
		type: String
	},
	setup () {
		return {
			privacyPolicies,
			activePrivacyPolicies
		}
	},
	data () {
		return {
			customPrivacyPolicyEnabled: false,
			selectedLanguageId: null,
			selectedPrivacyPolicy: null
		}
	},
	computed: {
		filteredAssignedLanguages () {
			const filteredLanguages = this.assignedLanguages.filter(x => !this.customPrivacyPolicies.some(y => y.languageId === x.value))
			return filteredLanguages
		},
		tableHeaders () {
			return [
				{ value: 'languageName', text: 'Language', width: '15%' },
				{ value: 'privacyPolicyId', text: 'Privacy Policy ID', width: '10%' },
				{ value: 'privacyPolicyName', text: 'Privacy Policy Name', width: '20%' },
				{ value: 'privacyPolicyVersionId', text: 'Cassie Version ID', width: '25%' },
				{ value: 'privacyPolicyExternalVersionRef', text: 'Client Version Identifier', width: '10%' },
				{ value: 'action', text: 'Action', width: '10%' }
			]
		},
		versionOptions () {
			const selectedPrivacyPolicyVersions = privacyPolicies.value.find(({ privacyPolicyId }) => privacyPolicyId === this.selectedPrivacyPolicy.value).versions.map(version => ({
				...version,
				value: version.externalVersionRef,
				text: version.externalVersionRef
			}))
			const useLatestVersion = {
				value: 'Always Use Latest Version',
				text: 'Always Use Latest Version'
			}
			return [
				useLatestVersion,
				...selectedPrivacyPolicyVersions
			]
		},
		brandFilteredActivePrivacyPolicies () {
			let filteredPrivacyPolicies = []

			if (this.brandId === UNIVERSAL_BRAND) {
				filteredPrivacyPolicies = activePrivacyPolicies.value.filter(({ brandId }) => brandId === UNIVERSAL_BRAND)
			} else {
				filteredPrivacyPolicies = activePrivacyPolicies.value.filter(({ brandId }) => brandId === this.brandId || brandId === UNIVERSAL_BRAND)
			}
			return filteredPrivacyPolicies
		},
		allowAddPrivacyPolicy () {
			return this.selectedLanguageId !== null && this.selectedPrivacyPolicy !== null && this.selectedPrivacyPolicyVersion !== null
		}
	},
	async created () {
		if (this.customPrivacyPolicies.length) {
			this.customPrivacyPolicyEnabled = true
		}
		this.updatePrivacyPoliciesEnabled()
	},
	methods: {
		updatePrivacyPolicies (customPrivacyPolicies) {
			this.$emit('update-privacy-policies', customPrivacyPolicies)
		},
		updatePrivacyPoliciesEnabled () {
			this.$emit('update-privacy-policies-enabled', this.customPrivacyPolicyEnabled)
		},
		getLatestVersion () {
			this.selectedPrivacyPolicyVersion = this.versionOptions[0]
		},
		addPrivacyPolicy () {
			const language = this.assignedLanguages.find(({ value }) => value === this.selectedLanguageId)
			this.updatePrivacyPolicies([
				...this.customPrivacyPolicies,
				{
					languageId: language.value,
					languageName: language.text,
					languageCode: language.languageCode,
					privacyPolicyId: this.selectedPrivacyPolicy.value,
					privacyPolicyName: this.selectedPrivacyPolicy.text,
					privacyPolicyVersionId: this.selectedPrivacyPolicyVersion.versionId || null,
					privacyPolicyExternalVersionRef: this.selectedPrivacyPolicyVersion.externalVersionRef
				}
			])
			this.selectedLanguageId = null
			this.selectedPrivacyPolicy = null
			this.selectedPrivacyPolicyVersion = null
		},
		removeCustomPrivacyPolicy (index) {
			const customPrivacyPolicies = [...this.customPrivacyPolicies]
			this.$delete(customPrivacyPolicies, index)
			this.$emit('update-privacy-policies', customPrivacyPolicies)
		},
		displayLanguageName (languageId) {
			const language = this.assignedLanguages.find(({ value }) => value === languageId)
			return language ? language.text : ''
		},
		displayPrivacyPolicyName (id) {
			const privacyPolicy = this.brandFilteredActivePrivacyPolicies.find(({ privacyPolicyId }) => id === privacyPolicyId)
			return privacyPolicy?.text
		}
	}
}
</script>
