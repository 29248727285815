export const submissionCode = '<script type="text/javascript">\n' +
	'\tvar form = document.forms[0]; //Change this to correspond to your form. The form\'s submit button cannot be called "submit"\n' +
	'\t\tform.addEventListener("submit", function (e) {\n' +
  '\t\te.preventDefault();\n' +
  '\t\tsubmitWidget(\'$preferenceWidgetId\', \'$accessKey\', \'$preferenceWidgetDivId\');\n' +
  '\t});\n' +
	'\n' +
  '\tfunction submitWidget(FormID, AccessKey, DivID) {\n' +
	'\t\tvar PostURL = \'$widgetApiSubmit\';\n' +
	'\t\tvar container = document.getElementById(DivID);\n' +
	'\t\tlet submission = {};\n' +
	'\n' +
	'\t\tsubmission.Preferences = GetPreferencesArray();\n' +
	'\n' +
	'\t\tvar dataSubjectDetails = {\n' +
	'\t\t\tSourceCode: \'sourcecode\',\n' +
	'\t\t\tResponseChannel: \'responsechannel\',\n' +
	'\t\t\tSyrenisID: \'syrenisid\',\n' +
	'\t\t\tTitle: \'title\',\n' +
	'\t\t\tForename: \'forename\',\n' +
	'\t\t\tMiddleName: \'middleName\',\n' +
	'\t\t\tSurname: \'surname\',\n' +
	'\t\t\tEmail1: \'email1\',\n' +
	'\t\t\tEmail2: \'email2\',\n' +
	'\t\t\tTelephone1: \'telephone1\',\n' +
	'\t\t\tTelephone2: \'telephone2\',\n' +
	'\t\t\tMobile: \'mobileNo\',\n' +
	'\t\t\tAddress1: \'address1\',\n' +
	'\t\t\tAddress2: \'address2\',\n' +
	'\t\t\tAddress3: \'address3\',\n' +
	'\t\t\tAddress4: \'address4\',\n' +
	'\t\t\tAddress5: \'address5\',\n' +
	'\t\t\tTown: \'town\',\n' +
	'\t\t\tCounty: \'county\',\n' +
	'\t\t\tCountry: \'country\',\n' +
	'\t\t\tPostcode: \'postCode\',\n' +
	'\t\t\tJobTitle: \'jobTitle\',\n' +
	'\t\t\tOrganisationName: \'organisation\',\n' +
	'\t\t\tUrn1: \'URN1\',\n' +
	'\t\t\tUrn2: \'URN2\',\n' +
	'\t\t\tUrn3: \'URN3\',\n' +
	'\t\t\tCustom1: \'custom1\',\n' +
	'\t\t\tCustom2: \'custom2\',\n' +
	'\t\t\tCustom3: \'custom3\',\n' +
	'\t\t\tCustom4: \'custom4\',\n' +
	'\t\t\tCustom5: \'custom5\',\n' +
	'\t\t\tCustom6: \'custom6\',\n' +
	'\t\t\tCustom7: \'custom7\',\n' +
	'\t\t\tCustom8: \'custom8\',\n' +
	'\t\t\tCustom9: \'custom9\',\n' +
	'\t\t\tCustom10: \'custom10\'\n' +
	'\t\t};\n' +
	'\n' +
	'\t\tappendSubmission(dataSubjectDetails, submission);\n' +
	'\n' +
	'\t\tvar formDetails = {\n' +
	'\t\t\tDivID: DivID,\n' +
	'\t\t\tLanguageCode: cassieWidgetLanguageCode,\n' +
	'\t\t\tFormID: FormID,\n' +
	'\t\t\tLicenseID: AccessKey,\n' +
	'\t\t\tPrivacyPolicyID: container.getAttribute("privacy-policy-id"),\n' +
	'\t\t\tPrivacyPolicyVersionID: container.getAttribute("privacy-policy-version-id"),\n' +
  '\t\t};\n' +
	'\t\tObject.entries(formDetails).forEach(([key, name]) => {\n' +
	'\t\t\tsubmission[key] = name;\n' +
	'\t\t});\n' +
	'\n' +
	'\t\tlet deviceGuid = document.cookie.replace(/(?:(?:^|.*;\\s*)SyrenisGuid_.+?\\s*\\=\\s*([^;]*).*$)|^.*$/, "$1");\n' +
	'\t\tif (deviceGuid != "") {\n' +
	'\t\t\tsubmission.DeviceGuid = deviceGuid;\n' +
	'\t\t}\n' +
	'\n' +
	'\t\tsubmitPreferences(PostURL, submission);\n' +
	'\t};\n' +
	'\n' +
	'\tasync function appendSubmission(sourceFields, submission) {\n' +
	'\t\t Object.entries(sourceFields).forEach(([key, name]) => {\n' +
	'\t\t\tlet input = form.elements[name];\n' +
	'\t\t\tlet value = input != undefined ? input.value : \'\';\n' +
	'\t\t\tif (key.includes([\'Email1\', \'Email2\'])) {\n' +
	'\t\t\t\tvalue = encodeURIComponent(value);\n' +
	'\t\t\t}\n' +
	'\t\t\tsubmission[key] = value;\n' +
	'\t\t});\n' +
  '\t}\n' +
	'\n' +
  '\tfunction submitPreferences(PostURL, submission) {\n' +
	'\t\tfetch(PostURL, {\n' +
	'\t\t\tmethod: \'POST\',\n' +
	'\t\t\theaders: { \'Content-type\': \'application/json\' },\n' +
	'\t\t\tbody: JSON.stringify(submission),\n' +
	'\t\t})\n' +
  '\t}\n' +
  '</script>\n'

export const widgetEmbedCode2 = '<script src="$widgetUrl"></script>\n' +
	'<script type="text/javascript">\n' +
	'document.addEventListener("DOMContentLoaded", function (event) {\n' +
	'\tGetWidget("$preferenceWidgetId", "$licenceKey", "$accessKey", "$preferenceWidgetDivId", "$optionAppearance" ,"$language")\n' +
	'\tvar CookieConsentID = checkCookie("CookieConsentID")\n' +
	'});\n' +
	'</script>\n'

export const widgetEmbedCode2Dynamic = '<script>\n' +
	'\tfunction getRootDomain(url) {\n' +
	'\t\ttry {\n' +
	'\t\t\tconst domain = new URL(url).hostname;\n' +
	'\t\t\tconst elems = domain.split(".");\n' +
	'\t\t\tconst iMax = elems.length - 1;\n' +
	'\t\t\tconst isSecondLevel = elems.length >= 3 && (elems[iMax] + elems[iMax - 1]).length <= 5;\n' +
	'\t\t\treturn elems.splice(isSecondLevel ? -3 : -2).join(".");\n' +
	'\t\t} catch (error) {\n' +
	'\t\t\treturn null;\n' +
	'\t\t}\n' +
	'\t}\n' +
	'\n' +
	'\tfunction loadScript(src, onSuccess, onError) {\n' +
	'\t\tconst script = document.createElement(\'script\');\n' +
	'\t\tscript.src = src;\n' +
	'\t\tscript.onload = () => {\n' +
	'\t\t\tif (onSuccess) onSuccess();\n' +
	'\t\t};\n' +
	'\t\t	script.onerror = () => {\n' +
	'\t\t\tif (onError) onError();\n' +
	'\t\t};\n' +
	'\t\tdocument.head.appendChild(script);\n' +
	'\t}\n' +
	'\n' +
	'\tfunction initCassieWidget() {\n' +
	'\t\twindow.onload = function() {\n' +
	'\t\t\tGetWidget("$preferenceWidgetId", "$licenceKey", "$accessKey", "$preferenceWidgetDivId", "$optionAppearance" ,"$language")\n' +
	'\t\t\tvar CookieConsentID = checkCookie("CookieConsentID")\n' +
	'\t\t};\n' +
	'\t}\n' +
	'\n' +
	'\tfunction loadScriptWithFallback() {\n' +
	'\t\tconst rootDomain = getRootDomain(window.location.href);\n' +
	'\t\tlet scriptUrl = rootDomain ? `$dynamicWidgetUrl` : \'$widgetUrl\';\n' +
	'\t\twindow.cassieResourceRootDomain = rootDomain;\n' +
	'\n' +
	'\t\tloadScript(scriptUrl, () => {\n' +
	'\t\t\tinitCassieWidget();\n' +
	'\t\t}, () => {\n' +
	'\t\t\twindow.cassieResourceRootDomain = null;\n' +
	'\t\t\tloadScript(\'$widgetUrl\', () => {\n' +
	'\t\t\t\tinitCassieWidget();\n' +
	'\t\t\t});\n' +
	'\t\t});\n' +
	'\t}\n' +
	'\n' +
	'\tloadScriptWithFallback();\n' +
	'</script>'

export const consentCollectionWidgetHeadCode = `<script>
	function getRootDomain(url) {
		try {
			const elems = new URL(url).hostname.split('.');
			const isSecondLevel = elems.length >= 3 && (elems.slice(-2).join('')).length <= 5;
			return elems.slice(isSecondLevel ? -3 : -2).join('.');
		} catch {
			return null;
		}
	}

	function loadWidgetScript(src, onError) {
		const script = document.createElement('script');
		script.src = src;
		script.type = 'module';
		script.onerror = onError;
		document.head.appendChild(script);
	}

	(function () {
		const rootDomain = getRootDomain(window.location.href);
		const defaultUrl = '$widgetScriptCassieUrl';
		const widgetUrl = rootDomain ? \`$widgetScriptUrl\` : defaultUrl;
		window.cdnDomain = rootDomain || 'cassiecloud.com';
		loadWidgetScript(widgetUrl, () => {
			window.cdnDomain = 'cassiecloud.com';
			loadWidgetScript(defaultUrl);
		});
	})()
</script>`

export const consentCollectionWidgetMinifiedCode = `<script>
	function g(z){try{const a=new URL(z).hostname.split('.');const b=a.length>=3&&(a.slice(-2).join('')).length<=5;return a.slice(b?-3:-2).join('.')}catch{return null}}function h(x,y){const c=document.createElement('script');c.src=x;c.type='module';c.onerror=y;document.head.appendChild(c)}(function(){const d=g(window.location.href);const e='$widgetScriptCassieUrl';const f=d?\`$widgetScriptUrl\`:e;window.cdnDomain=d||'cassiecloud.com';h(f,()=>{window.cdnDomain='cassiecloud.com';h(e)})})()
</script>`
