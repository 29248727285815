export default {
	methods: {
		sortParentAndChildChannels (channels) {
			const parentandDoubleOptInChannels = channels.filter(({ channelType }) => channelType.typeID !== 2).sort((a, b) => a.channelName.localeCompare(b.channelName))
			const nestedChannels = parentandDoubleOptInChannels.map(parentChannel => {
				const childChannels = channels.filter(({ channelType }) => channelType.parentChannelID === parentChannel.channelId)
				return {
					...parentChannel,
					originalChannelName: parentChannel.channelName,
					childChannels
				}
			}).sort((a, b) => a.channelName.localeCompare(b.channelName)).flat()

			const flattenParentChildChannelsArray = array => {
				return array.reduce((acc, obj) => {
					const { childChannels, ...rest } = obj

					acc.push(rest)

					childChannels.forEach(childChannel => {
						const formattedChildChannel = {
							...childChannel,
							channelName: `\xa0\xa0\xa0 \u2514 ${childChannel.channelName}`,
							originalChannelName: childChannel.channelName
						}
						acc.push(formattedChildChannel)
					})

					return acc
				}, [])
			}
			return flattenParentChildChannelsArray(nestedChannels)
		}
	}
}
