import { format } from 'date-fns'
import { DATE_FORMAT } from '../date-formatting.js'
import { configApi } from '../http-client.js'

const DEFAULT_LANGUAGE = 0

export const getPreferenceChannels = () => {
	return configApi.get('/api/Channels').then(({ data }) => JSON.parse(JSON.stringify({
		data: {
			channels: data.channels.map(channel => ({
				...channel,
				brandId: channel.brandID,
				createdDate: format(new Date(channel.createdDate), DATE_FORMAT),
				channelId: channel.channelID,
				channelID: undefined,
				metaData: channel.metaData.map(({ keyId, keyName, value }) => ({
					metaDataKeyId: keyId,
					metaDataKeyName: keyName,
					metaDataValue: value
				})),
				statements: channel.statements?.map(statement => ({
					...statement,
					liveStatementText: statement.liveStatements?.find(({ languageId }) => languageId === DEFAULT_LANGUAGE)?.statementText,
					draftStatementText: statement.draftStatements?.find(({ languageId }) => languageId === DEFAULT_LANGUAGE)?.statementText,
					defaultLanguageDraftStatement: statement.draftStatements?.find(({ languageId }) => languageId === DEFAULT_LANGUAGE),
					metaData: statement.metaData.map(({ keyId, keyName, value }) => ({
						metaDataKeyId: keyId,
						metaDataKeyName: keyName,
						metaDataValue: value
					}))
				}))
			})).reverse(),
			channelCategories: data.channelCategories,
			transactionTypeId: data.transactionType,
			overrideUniversalConsent: data.overrideUniversalConsent
		}
	})))
}

export const deleteChannel = channelId => configApi.delete(`/api/Channels/${channelId}`)

export const createChannel = channel => {
	const newChannel = {
		channelName: channel.channelName,
		channelCategory: channel.channelCategory,
		channelDescription: channel.channelDescription,
		brandId: channel.brandId,
		expiryInDays: channel.expiryDays ? parseInt(channel.expiryDays) : 0,
		channelType: {
			typeId: channel.channelType.typeID,
			parentChannelId: channel.channelType.parentChannelID ? channel.channelType.parentChannelID : null,
			doubleOptInRelatedChannelId: channel.channelType.doubleOptInRelatedChannelID ? channel.channelType.doubleOptInRelatedChannelID : null,
			doubleOptInRelatedStatementId: channel.channelType.doubleOptInRelatedStatementID ? channel.channelType.doubleOptInRelatedStatementID : null,
			doubleOptInConfirmationEmailId: channel.channelType.doubleOptInConfirmationEmailID ? channel.channelType.doubleOptInConfirmationEmailID : null,
			doubleOptInEligible: channel.channelType.doubleOptInEligible
		},
		externalValues: channel.metaData?.map(meta => ({
			externalMetaDataKeyId: meta.metaDataKeyId,
			externalFieldValue: meta.metaDataValue
		})),
		transactionTypeId: channel.transactionTypeId,
		overrideUniversalConsent: channel.allowUniversalConsent
	}
	return configApi.post('/api/Channels', newChannel)
}

export const updateChannel = channel => {
	const updatedChannel = {
		channelId: channel.channelId,
		channelName: channel.channelName,
		channelCategory: channel.channelCategory,
		channelDescription: channel.channelDescription,
		brandId: channel.brandId,
		expiryInDays: channel.expiryDays ? parseInt(channel.expiryDays) : 0,
		channelType: {
			typeId: channel.channelType.typeID,
			parentChannelId: channel.channelType.parentChannelID ? channel.channelType.parentChannelID : null,
			doubleOptInRelatedChannelId: channel.channelType.doubleOptInRelatedChannelID ? channel.channelType.doubleOptInRelatedChannelID : null,
			doubleOptInRelatedStatementId: channel.channelType.doubleOptInRelatedStatementID ? channel.channelType.doubleOptInRelatedStatementID : null,
			doubleOptInConfirmationEmailId: channel.channelType.doubleOptInConfirmationEmailID ? channel.channelType.doubleOptInConfirmationEmailID : null,
			doubleOptInEligible: channel.channelType.doubleOptInEligible
		},
		externalValues: channel.metaData?.map(meta => ({
			externalMetaDataKeyId: meta.metaDataKeyId,
			externalFieldValue: meta.metaDataValue
		})),
		transactionTypeId: channel.transactionTypeId,
		overrideUniversalConsent: channel.allowUniversalConsent
	}
	return configApi.put('/api/Channels', updatedChannel)
}

export const updateStatement = statement => configApi.post('/api/Statements/Upsert', {
	...statement,
	statementId: statement.statementID
})

export const createChannelWithStatementsAndActions = channel => {
	const channelWithStatements = {
		channelName: channel.channelName,
		channelCategory: channel.channelCategory,
		channelDescription: channel.channelDescription,
		brandId: channel.brandId,
		expiryInDays: channel.expiryDays ? parseInt(channel.expiryDays) : 0,
		channelType: {
			typeId: channel.channelType.typeID,
			parentChannelId: channel.channelType.parentChannelID || null,
			doubleOptInRelatedChannelId: channel.channelType.doubleOptInRelatedChannelID || null,
			doubleOptInRelatedStatementId: channel.channelType.doubleOptInRelatedStatementID || null,
			doubleOptInConfirmationEmailId: channel.channelType.doubleOptInConfirmationEmailID || null,
			doubleOptInEligible: channel.channelType.doubleOptInEligible
		},
		externalValues: channel.metaData?.map(meta => ({
			externalMetaDataKeyId: meta.metaDataKeyId,
			externalFieldValue: meta.metaDataValue
		})) || [],
		statements: channel.statements.map(statement => ({
			statementText: statement.statementText,
			isOptIn: statement.isOptIn,
			defaultValue: statement.defaultValue,
			externalValues: statement.externalValues,
			actionId: statement.actionId,
			statementId: statement.statementID
		})),
		transactionTypeId: channel.transactionTypeId,
		overrideUniversalConsent: channel.allowUniversalConsent,
		doubleOptInSelectedStatementId: channel.doubleOptInSelectedStatementId || null
	}
	return configApi.post('api/Channels/CreateChannelWithStatementsAndActions', channelWithStatements).then(response => {
		return {
			doubleOptInSelectedStatementId: response.data.doubleOptInSelectedStatementId,
			channelId: response.data.newChannelId
		}
	}).catch(function (error) {
		throw error.response.data
	})
}

export const upsertChannelTranslation = channelTranslation => configApi.post('/api/Channels/ChannelTranslations/Upsert', channelTranslation)
export const deleteChannelTranslation = channelTranslationId => configApi.delete(`/api/Channels/Translations/${channelTranslationId}`)
