var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{ref:"customValidator",class:_vm.tooltipText || _vm.reserveTooltipSpace ? 'd-flex flex-row cassie-horizontal-sm' : '',attrs:{"name":_vm.fieldName || _vm.$props.label,"rules":_vm.rules,"mode":_vm.validationMode,"custom-messages":_vm.customMessages,"detect-input":_vm.validateField,"skip-if-empty":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',_vm._g(_vm._b({ref:"select",staticClass:"cassie-dropdown",class:[_vm.small ? 'cassie-dropdown--small' : '', _vm.getQaSelectorClass(_vm.$props)],attrs:{"label":_vm.label,"readonly":_vm.viewOnly,"persistent-placeholder":_vm.viewOnly,"error-messages":errors.length ? [errors[0]] : [],"rules":[],"items":_vm.sortedItems,"hide-details":!_vm.hasRules || !errors.length,"menu-props":{
			contentClass: ("dropdown-menu cassie-dropdown " + (_vm.small ? 'cassie-dropdown--small' : '')),
			value: _vm.menuOpen
		},"data-testid":_vm.testId,"dense":""},on:{"click":function($event){_vm.menuOpen = true}},scopedSlots:_vm._u([(_vm.selectAll)?{key:"prepend-item",fn:function(){return [_vm._t("prepend-item")]},proxy:true}:null,(_vm.multiple)?{key:"selection",fn:function(ref){
		var item = ref.item;
		var index = ref.index;
return [_c('span',{staticClass:"v-select__selection"},[(index === 0)?_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.itemText ? _vm.value[0] : item)+" ")]):_vm._e(),(_vm.value && index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.value.length - 1)+" others) ")]):_vm._e()])]}}:(_vm.$scopedSlots.selection)?{key:"selection",fn:function(props){return [_vm._t("selection",null,null,props)]}}:null],null,true)},'v-select',_vm.$props,false),_vm.$listeners)),(_vm.tooltipText)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
		var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"light":""}},on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.tooltipText),expression:"tooltipText"}]})]):_vm._e(),(_vm.reserveTooltipSpace)?_c('div',{staticStyle:{"width":"25px"}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }