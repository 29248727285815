<template>
	<validation-provider ref="builder">
		<cassie-form-builder
			mode="build"
			:type="type"
			:headers="mappedHeaders"
			:footers="mappedFooters"
			:channels="mappedChannels"
			:translation-mode="translationMode"
			:read-only="!userFullPermissions"
			class="cassie-builder"
		/>
	</validation-provider>
</template>

<script>
import sortParentAndChildChannelsMixin from '../../../../../shared/mixins/sort-parent-and-child-channels.js'
import { UNIVERSAL_BRAND } from '../../../../../shared/state/brands.js'
export default {
	mixins: [sortParentAndChildChannelsMixin],
	props: {
		type: {
			type: String,
			required: true
		},
		channels: {
			type: Array,
			required: true
		},
		headers: {
			type: Array,
			required: false
		},
		footers: {
			type: Array,
			required: false
		},
		translationMode: {
			type: Boolean,
			default: false
		},
		brandId: Number,
		userFullPermissions: Boolean
	},
	computed: {
		mappedHeaders () {
			const headers = this.headers
			if (!headers) {
				return []
			}
			headers.sort((a, b) => a.name.localeCompare(b.name))
			return JSON.stringify(headers)
		},
		mappedFooters () {
			const footers = this.footers
			if (!footers) {
				return []
			}
			footers.sort((a, b) => a.name.localeCompare(b.name))
			return JSON.stringify(footers)
		},
		mappedChannels () {
			const channelsWithStatements = this.channels.filter(channel => channel.statements?.length)
				.filter(channel => channel.brandId === this.brandId || channel.brandId === UNIVERSAL_BRAND)
			const sortedChannels = this.sortParentAndChildChannels(channelsWithStatements).map(channel => ({
				value: channel.channelId,
				label: channel.originalChannelName,
				selectLabel: channel.channelName,
				statements: channel.statements?.map(statement => ({
					value: statement.statementID,
					label: statement.statementText,
					defaultValue: statement.defaultValue
				})).sort((a, b) => a.label.localeCompare(b.label))
			}))
			return JSON.stringify(sortedChannels)
		}
	}
}
</script>

<style>
.cassie-builder {
	height: calc(100vh - 298px);
	max-height: calc(100vh - 298px);
}
</style>
