<template>
	<validation-provider
		#default="{ errors }"
		:name="fieldName || $props.label"
		:rules="rules"
		:mode="validationMode"
		:custom-messages="customMessages"
		:detect-input="validateField"
		tag="div"
		:class="tooltipText || reserveTooltipSpace ? 'd-flex flex-row cassie-horizontal-sm' : ''"
	>
		<v-text-field
			v-bind="$props"
			:readonly="$props.readonly || viewOnly"
			:error-messages="errors.length ? [errors[0]] : []"
			:rules="[]"
			:hide-details="!hasRules || !errors.length"
			:class="[small ? 'cassie-text-field--small' : '', getQaSelectorClass($props)]"
			dense
			:data-testid="testId"
			class="cassie-text-field"
			v-on="$listeners"
		/>
		<v-tooltip
			v-if="tooltipText"
			right
		>
			<template #activator="{ on }">
				<v-icon
					light
					v-on="on"
				>
					mdi-information
				</v-icon>
			</template>
			<div v-dompurify-html="tooltipText" />
		</v-tooltip>
		<div
			v-if="reserveTooltipSpace"
			style="width: 24px;"
		/>
	</validation-provider>
</template>

<script>
import { VTextField } from 'vuetify/lib'
import FieldValidationMixin from '../validation/field-validation-mixin.js'

export default {
	name: 'text-field',
	extends: VTextField,
	mixins: [FieldValidationMixin],
	props: {
		viewOnly: {
			type: Boolean,
			default: false
		},
		light: {
			type: Boolean,
			default: true
		},
		outlined: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		tooltipText: {
			type: String
		},
		reserveTooltipSpace: {
			type: Boolean,
			default: false
		},
		testId: {
			type: String,
			default: 'cassie_text_field'
		}

	},
	methods: {
		getQaSelectorClass (props) {
			let trimmedLabel = props.label
			if (trimmedLabel) {
				if (trimmedLabel.includes(' *')) {
					trimmedLabel = trimmedLabel.replace(' *', '')
				}
				trimmedLabel = trimmedLabel.replace(/\s/g, '-').toLowerCase()
			}
			return `qa-textfield-${trimmedLabel}`
		}
	}
}
</script>

<style lang="scss">
.cassie-text-field {
	&--small {
		.v-input__control .v-input__slot {
			min-height: 0 !important;
			padding: 0 8px !important;
			display: flex !important;
			align-items: center !important;
		}

		.v-input__control .v-input__slot .v-input__append-inner {
			margin-top: 4px !important;
		}

		.v-input__control .v-input__slot label {
			margin-top: -12px !important;
		}

		.v-input__control .v-input__slot label.v-label--active {
			margin: 0 0 0 5px !important;
		}

		.v-text-field__details {
			margin: 2px !important;
		}

		.v-label {
			top: 18px !important;
			font-size: 14px !important;
		}

		input {
			font-size: 85%;
		}

		.v-label--active {
			-webkit-transform: translateY(-25px) scale(0.65) !important;
			transform: translateY(-25px) scale(0.65) !important;
		}
	}
}
</style>
