<template>
	<div
		:class="{
			'cassie-radio-panel-parent': panel
		}"
	>
		<v-radio
			v-bind="$props"
			class="cassie-radio"
			:class="{
				'cassie-radio-reverse-label': reverseLabel,
				'cassie-radio-panel': panel
			}"
			:data-testid="testId"
			v-on="$listeners"
		/>
		<slot name="radio-action" />
	</div>
</template>

<script>
import { VRadio } from 'vuetify/lib'

export default {
	name: 'radio',
	extends: VRadio,
	props: {
		reverseLabel: Boolean,
		panel: {
			type: Boolean,
			default: false
		},
		testId: {
			type: String,
			default: 'cassie_radio_button'
		}
	}
}
</script>

<style lang="scss">
.cassie-radio-panel-parent {
	margin-bottom: 10px;
}
.cassie-radio {
	&-reverse-label {
		display: flex;
		flex-direction: row-reverse;

		.v-label {
			margin-right: 10px;
		}
	}
	&-panel {
    padding: 15px 5px;
    background-color: #f8f9fd !important;
	}
	.cassie-radio-panel-parent:has(&-panel.v-item--active), &-panel.v-item--active {
		background-color: #DDF9F8 !important;
	}
}
</style>
