
import { configApi } from '../http-client.js'

export const getExternalMetaData = () => configApi.get('/api/ExternalMetaData').then(({ data }) => ({
	...data,
	externalMetaData: data.externalMetaData.map(metadata => ({
		...metadata,
		dropDownValues: metadata.dropDownValues.sort((a, b) => a.displayOrder - b.displayOrder)
	}))
}))

export const createExternalMetaData = externalMetaData => configApi.post('/api/ExternalMetaData', externalMetaData)

export const updateExternalMetaData = (id, externalMetaData) => {
	return configApi.put(`/api/ExternalMetaData/${id}`, externalMetaData)
}

export const updateExternalMetaDataAssignment = externalMetaDataAssignment => {
	return configApi.put(`/api/ExternalMetaData/EditMetaDataAssignment`, externalMetaDataAssignment)
}

export const putStatementAction = statement => {
	return configApi.put('api/Statements/StatementAction', statement)
}

export const createExternalMetaDataAssignment = (externalMetaDataAssignment, componentId, componentTypeId) => {
	const metaDataAssignment = {
		assignments: externalMetaDataAssignment.map(assignment => ({
			metaDataId: assignment.metaDataKeyId,
			value: assignment.metaDataValue,
			componentTypeId: componentTypeId,
			componentId: componentId
		}))
	}

	return configApi.post(`/api/ExternalMetaData/CreateMetaDataAssignment`, metaDataAssignment)
}

export const deleteExternalMetaDataAssignment = (componentId, componentTypeId) => {
	return configApi.delete(`/api/ExternalMetaData/DeleteMetaDataAssignment/${componentId}/${componentTypeId}`)
}

export const deleteExternalMetaData = externalMetaDataId => configApi.delete(`/api/ExternalMetaData/${externalMetaDataId}`)
