export const headersAndFootersTypeIdEnum = {
	PREFERENCE_PAGES_AND_WIDGETS: 1,
	CONSENT_BANNERS: 2,
	CS_PORTAL: 3,
	PREFERENCE_PAGE_FOOTER: 4
}

export const headersAndFootersTypeIdEnumOptions = {
	[headersAndFootersTypeIdEnum.PREFERENCE_PAGES_AND_WIDGETS]: 'Consent Capture',
	[headersAndFootersTypeIdEnum.CONSENT_BANNERS]: 'Cookie Banners',
	[headersAndFootersTypeIdEnum.CS_PORTAL]: 'CS Portal',
	[headersAndFootersTypeIdEnum.PREFERENCE_PAGE_FOOTER]: 'Website Page Footer'
}

export const headersAndFootersTypeDropdown = [
	{
		value: null,
		text: 'View All'
	},
	{
		value: headersAndFootersTypeIdEnum.CONSENT_BANNERS,
		text: 'Cookie Banners'
	},
	{
		value: headersAndFootersTypeIdEnum.CS_PORTAL,
		text: 'CS Portal'
	},
	{
		value: headersAndFootersTypeIdEnum.PREFERENCE_PAGES_AND_WIDGETS,
		text: 'Consent Capture'
	},
	{
		value: headersAndFootersTypeIdEnum.PREFERENCE_PAGE_FOOTER,
		text: 'Website Page Footer'
	}
]
