import { format } from 'date-fns'
import { DATE_FORMAT } from '../date-formatting.js'
import { configApi } from '../http-client.js'

export const HEADER = 'Header'
export const FOOTER = 'Footer'

export const getHeaders = (typeId = '') => {
	return configApi.get(`/api/headers?TypeId=${typeId}`).then(({ data: { headers } }) => {
		return {
			data: headers !== null
				? headers.map(header => ({
					name: header.headerName,
					id: header.id,
					type: HEADER,
					brandName: header.brandName,
					brandId: header.brandId,
					html: header.headerHtml,
					typeId: header.typeId,
					typeDescription: header.typeDescription,
					usage: header.usageCount,
					createdDate: format(new Date(header.createdDate), DATE_FORMAT),
					externalMetaData: header.headerMetaData.map(headerMetaData => ({
						externalDataId: headerMetaData.externalMetaDataId,
						name: headerMetaData.externalDataName,
						value: headerMetaData.externalDataValue
					}))
				}))
				: null
		}
	})
}

export const getFooters = (typeId = '') => {
	return configApi.get(`/api/footers?TypeId=${typeId}`).then(({ data: { footers } }) => {
		return {
			data: footers !== null
				? footers.map(footer => ({
					name: footer.footerName,
					id: footer.id,
					type: FOOTER,
					brandName: footer.brandName,
					brandId: footer.brandId,
					html: footer.footerHtml,
					typeId: footer.typeId,
					typeDescription: footer.typeDescription,
					createdDate: format(new Date(footer.createdDate), DATE_FORMAT),
					usage: footer.usageCount,
					externalMetaData: footer.footerMetaData.map(footerMetaData => ({
						externalDataId: footerMetaData.externalMetaDataId,
						name: footerMetaData.externalDataName,
						value: footerMetaData.externalDataValue
					}))
				}))
				: null
		}
	})
}

export const getHeader = headerId => {
	return configApi.get(`/api/headers/${headerId}`).then(({ data: { header } }) => {
		return {
			data: header.map(header => ({
				name: header.headerName,
				html: header.headerHtml,
				id: header.id,
				brandName: header.brandName,
				brandId: header.brandId,
				createdByName: header.createdByName,
				createdDate: header.createdDate,
				editedByName: header.editedByName,
				lastEditedDate: header.lastEditedDate,
				type: HEADER,
				externalMetaData: header.headerMetaData
			}))
		}
	})
}

export const getFooter = footerId => {
	return configApi.get(`/api/footers/${footerId}`).then(({ data: { footer } }) => {
		return {
			data: footer.map(footer => ({
				name: footer.footerName,
				html: footer.footerHtml,
				id: footer.id,
				brandName: footer.brandName,
				brandId: footer.brandId,
				createdByName: footer.createdByName,
				createdDate: footer.createdDate,
				editedByName: footer.editedByName,
				lastEditedDate: footer.lastEditedDate,
				type: FOOTER,
				externalMetaData: footer.footerMetaData
			}))
		}
	})
}

export const createHeader = header => {
	return configApi.post('/api/headers', header)
}
export const createFooter = footer => {
	return configApi.post('/api/footers', footer)
}

export const updateHeader = header => {
	return configApi.put('/api/headers', header)
}
export const updateFooter = footer => {
	return configApi.put('/api/footers', footer)
}

export const deleteHeader = headerId => {
	return configApi.delete(`/api/headers/${headerId}`)
}

export const deleteFooter = footerId => {
	return configApi.delete(`/api/footers/${footerId}`)
}

export const getHeaderTranslations = (typeId = '') => {
	return configApi.get(`/api/headers?TypeId=${typeId}`).then(({ data: { headers } }) => {
		return {
			headerTranslations: headers !== null
				? headers.map(header => {
					return header.translations.map(translation => ({
						...translation,
						translatedHtml: translation.translatedHeaderHtml,
						createdDate: translation.createdDate
							? format(new Date(translation.createdDate), DATE_FORMAT)
							: null,
						name: header.headerName,
						id: header.id,
						typeId: header.typeId,
						type: HEADER,
						brandName: header.brandName,
						brandId: header.brandId,
						html: header.headerHtml,
						typeDescription: header.typeDescription,
						metaData: header.headerMetaData
					}))
				})
				: null
		}
	})
}
export const createHeaderTranslation = headerTranslation => {
	return configApi.post(`/api/Headers/CreateHeaderTranslation`, headerTranslation)
}
export const updateHeaderTranslation = headerTranslation => {
	return configApi.put(`/api/Headers/UpdateHeaderTranslation`, headerTranslation)
}
export const createFooterTranslation = footerTranslation => {
	return configApi.post(`/api/Footers/CreateFooterTranslation`, footerTranslation)
}
export const updateFooterTranslation = footerTranslation => {
	return configApi.put(`/api/Footers/UpdateFooterTranslation`, footerTranslation)
}

export const deleteHeaderTranslation = headerTranslationId => {
	return configApi.delete(`/api/Headers/HeaderTranslations/${headerTranslationId}`)
}

export const getFooterTranslations = (typeId = '') => {
	return configApi.get(`/api/footers?TypeId=${typeId}`).then(({ data: { footers } }) => {
		return {
			footerTranslations: footers !== null
				? footers.map(footer => {
					return footer.translations.map(translation => ({
						...translation,
						translatedHtml: translation.translatedFooterHtml,
						createdDate: translation.createdDate
							? format(new Date(translation.createdDate), DATE_FORMAT)
							: null,
						name: footer.footerName,
						id: footer.id,
						type: footer,
						typeId: footer.typeId,
						brandName: footer.brandName,
						brandId: footer.brandId,
						html: footer.footerHtml,
						typeDescription: footer.typeDescription,
						metaData: footer.footerMetaData
					}))
				})
				: null
		}
	})
}
export const deleteFooterTranslation = footerTranslationId => {
	return configApi.delete(`/api/Footers/FooterTranslations/${footerTranslationId}`)
}
