<template>
	<v-row dense>
		<v-col cols="7">
			<SectionCard>
				<template #title>
					Position and Trigger
				</template>
				<template #body>
					<v-row dense>
						<v-col cols="9">
							<v-img
								v-if="consentCollectionWidget.positionId !== null"
								max-width="450px"
								:src="getPositionPreview"
							/>
						</v-col>
						<v-col
							v-if="consentCollectionWidget.executionTypeId"
							cols="3"
							class="text-center cassie-vertical-md"
						>
							<v-icon size="100">
								{{ getTriggerIcon }}
							</v-icon>
							<p>
								{{ getTriggerCaption }}
							</p>
						</v-col>
					</v-row>
				</template>
			</SectionCard>
		</v-col>
		<v-col
			cols="5"
			class="cassie-vertical-md"
		>
			<SectionCard>
				<template #title>
					Position
				</template>
				<template #body>
					<validation-provider>
						<v-radio-group
							:value.sync="consentCollectionWidget.positionId"
							dense
							hide-details
							class="my-0"
							:disabled="!userFullPermissions"
							@change="setPostion($event)"
						>
							<template
								v-for="radio in positionRadios"
							>
								<Radio
									:key="radio.value"
									:label="radio.label"
									:value="radio.value"
									:test-id="`widget_position-${radio.value}`"
									panel
								/>
							</template>
						</v-radio-group>
					</validation-provider>
				</template>
			</SectionCard>
			<SectionCard
				v-if="consentCollectionWidget.positionId !== INLINE_POSITION"
			>
				<template #title>
					Trigger
				</template>
				<template #body>
					<validation-provider>
						<v-radio-group
							:value.sync="consentCollectionWidget.executionTypeId"
							dense
							hide-details
							class="my-0"
							:disabled="!userFullPermissions"
							@change="setTrigger($event)"
						>
							<Radio
								label="Action Based"
								:value="3"
								test-id="widget_trigger_1"
								panel
							>
								<template #radio-action>
									<TextField
										v-if="consentCollectionWidget.executionTypeId === 3"
										:value.sync="consentCollectionWidget.cssSelector"
										label="Webpage Class or ID"
										class="ma-1"
										background-color="white"
										test-id="widget_webpage_class_or_id"
										rules="required"
										tooltip-text="
										<p>Specify the Class or ID of the object in your webpage which will prompt the widget</p>
										<p>Class fields should be prefixed with '.' e.g. '.class_button'</p>
										<p>ID fields should be prefixed with '#' e.g. '#id_button'</p>
										"
										@input="updateConsentCollectionWidget('cssSelector', $event)"
									/>
								</template>
							</Radio>
							<Radio
								label="Time Based"
								:value="2"
								test-id="widget_executionTypeId_2"
								panel
							>
								<template #radio-action>
									<TextField
										v-if="consentCollectionWidget.executionTypeId === 2"
										:value.sync="consentCollectionWidget.executionDelaySeconds"
										background-color="white"
										label="Delay in Seconds"
										type="number"
										rules="required"
										test-id="widget_delay_in_seconds"
										class="ma-1"
										@input="updateConsentCollectionWidget('executionDelaySeconds', $event)"
									/>
								</template>
							</Radio>
							<Radio
								label="Immediate"
								:value="1"
								test-id="widget_trigger_3"
								panel
							/>
						</v-radio-group>
					</validation-provider>
				</template>
			</SectionCard>
		</v-col>
	</v-row>
</template>

<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import Radio from '../../../../../../shared/components/radio.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
export default {
	components: {
		SectionCard,
		Radio,
		TextField
	},
	props: {
		consentCollectionWidget: Object,
		userFullPermissions: Boolean
	},
	data () {
		const INLINE_POSITION = 0
		return {
			INLINE_POSITION
		}
	},
	computed: {
		positionRadios () {
			return [
				{
					label: 'In Line',
					value: 0
				},
				{
					label: 'Top Left',
					value: 1
				},
				{
					label: 'Top Right',
					value: 2
				},
				{
					label: 'Bottom Left',
					value: 3
				},
				{
					label: 'Bottom Right',
					value: 4
				},
				{
					label: 'Center',
					value: 5
				}
			]
		},
		getPositionPreview () {
			switch (this.consentCollectionWidget.positionId) {
				case 0:
					return require('../../../../assets/images/consent-collection-widget-inline.svg')
				case 1:
					return require('../../../../assets/images/consent-collection-widget-top-left.svg')
				case 2:
					return require('../../../../assets/images/consent-collection-widget-top-right.svg')
				case 3:
					return require('../../../../assets/images/consent-collection-widget-bottom-left.svg')
				case 4:
					return require('../../../../assets/images/consent-collection-widget-bottom-right.svg')
				case 5:
					return require('../../../../assets/images/consent-collection-widget-centre.svg')
				default:
					return ''
			}
		},
		getTriggerIcon () {
			switch (this.consentCollectionWidget.executionTypeId) {
				case 1:
					return 'mdi-arrow-right'
				case 2:
					return 'mdi-timer-outline'
				case 3:
					return 'mdi-cursor-default-click-outline'
				default:
					return ''
			}
		},
		getTriggerCaption () {
			switch (this.consentCollectionWidget.executionTypeId) {
				case 1:
					return 'Immediate Display'
				case 2:
					return `${this.consentCollectionWidget.executionDelaySeconds}s Timer Display`
				case 3:
					return 'Display on interaction'
				default:
					return ''
			}
		}
	},
	methods: {
		setTrigger (value) {
			this.updateConsentCollectionWidget('executionTypeId', value)
		},
		async setPostion (value) {
			await this.updateConsentCollectionWidget('positionId', value)
			if (value === this.INLINE_POSITION) {
				this.updateConsentCollectionWidget('executionTypeId', 1)
			}
		},
		updateConsentCollectionWidget (key, value) {
			this.$emit('update:consentCollectionWidget', {
				...this.consentCollectionWidget,
				[key]: value
			})
		}
	}
}
</script>
