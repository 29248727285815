import { format } from 'date-fns'
import { configApi } from '../http-client.js'
import { DATE_FORMAT } from '../date-formatting.js'

export const getConsentCollectionWidgets = () => configApi.get('/api/ConsentCollection/Widget').then(({ data }) => ({
	consentCollectionWidgets: data.consentCollectionWidgets.map(consentCollectionWidget => ({
		...consentCollectionWidget,
		createdDate: format(new Date(consentCollectionWidget.createdDate), DATE_FORMAT)
	}))
}))

export const getConsentCollectionWidget = id => configApi.get(`/api/ConsentCollection/Widget/${id}`).then(({ data }) => ({
	consentCollectionWidget: {
		...data,
		name: data.consentCollectionWidgetName,
		description: data.consentCollectionWidgetDescription,
		customDefaultLanguageId: data.defaultLanguageId,
		externalValues: data.externalValues.map(externalValue => ({
			metaDataKeyId: externalValue.externalMetaDataId,
			metaDataKeyName: externalValue.externalDataName,
			metaDataValue: externalValue.externalDataValue
		}))
	}
}))

export const createConsentCollectionWidget = widget => configApi.post('/api/ConsentCollection/Widget', widget)

export const updateConsentCollectionWidget = (id, widget) => configApi.put(`/api/ConsentCollection/Widget/${id}`, widget)

export const deleteConsentCollectionWidget = id => configApi.delete(`/api/ConsentCollection/Widget/${id}`)
