import { configApi } from '../http-client.js'

export const getAssignedLanguages = () => configApi.get('/api/Languages/AssignedLanguages')
export const getLanguages = () => configApi.get('/api/Languages')
export const upsertAssignedLanguage = async language => {
	const assignedLanguage = {
		languageLookupId: language.language.languageLookupId,
		allowPrefPageAutoTranslation: language.allowPrefPageAutoTranslation,
		enabledInPublicPortal: language.enabledInPublicPortal
	}
	await configApi.post('/api/Languages/AssignedLanguages/Upsert', assignedLanguage)
}
export const createLanguage = async (languageLookupId, languageName, languageCode, isEnabledInPublicPortal, isAutoTranslateEnabled) => {
	return await configApi.post('/api/languages',
		{ languageLookupId, languageName, languageCode, isEnabledInPublicPortal, isAutoTranslateEnabled }
	)
}
export const updateLanguage = async (languageId, languageName, languageCode, isEnabledInPublicPortal, isAutoTranslateEnabled) => {
	return await configApi.put('/api/languages',
		{ languageId, languageName, languageCode, isEnabledInPublicPortal, isAutoTranslateEnabled }
	)
}
export const updateDefaultLanguageName = async defaultLanguageName => {
	const defaultLanguageNameData = {
		DefaultLanguageName: defaultLanguageName
	}
	await configApi.put('/api/Languages/DefaultLanguageName', defaultLanguageNameData)
}
export const getLanguageTabTranslations = () => configApi.get('/api/Languages/GetLanguageTabTranslation')
export const updateOptionLabelTranslation = optionLabelTranslation => {
	return configApi.put(`/api/Languages/OptionLabelTranslations`, optionLabelTranslation)
}
