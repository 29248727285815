<template>
	<v-tooltip
		v-if="tooltipText"
		top
	>
		<template #activator="{ on }">
			<v-btn
				v-bind="$props"
				small
				icon
				:class="getQaSelectorClass($props)"
				:data-testid="testId"
				v-on="on"
			>
				<v-icon
					small
					:color="$props.color"
					style="transform: unset;"
					v-on="$listeners"
				>
					<slot />
				</v-icon>
			</v-btn>
		</template>
		<span>
			{{ tooltipText }}
		</span>
	</v-tooltip>
	<v-btn
		v-else
		v-bind="$props"
		small
		icon
		:class="getQaSelectorClass($props)"
		:data-testid="testId"
		v-on="$listeners"
	>
		<v-icon
			small
			:color="$props.color"
			style="transform: unset;"
		>
			<slot />
		</v-icon>
	</v-btn>
</template>

<script>
import { VBtn } from 'vuetify/lib'

export default {
	name: 'icon-button',
	extends: VBtn,
	props: {
		smallIcon: Boolean,
		tooltipText: String,
		testId: {
			type: String,
			default: 'cassie_iconbutton'
		}
	},
	methods: {
		getQaSelectorClass (props) {
			return 'qa-iconbutton'
		}
	}
}
</script>
