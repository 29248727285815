import { format } from 'date-fns'
import { DATE_FORMAT } from '../date-formatting.js'
import { configApi } from '../http-client.js'

export const deleteSystemEmail = systemEmailId => configApi.delete(`api/SystemEmails/${systemEmailId}`)

export const getSystemEmails = () => {
	return configApi.get('/api/SystemEmails').then(({ data }) => JSON.parse(JSON.stringify({
		data: {
			systemEmails: data.systemEmails.map(systemEmail => ({
				...systemEmail,
				systemEmailId: systemEmail.systemEmailId,
				senderEmailAddressId: systemEmail.senderEmailAddressId,
				systemEmailName: systemEmail.systemEmailName,
				systemEmailSubject: systemEmail.systemEmailSubject,
				systemEmailHtml: systemEmail.systemEmailHtml,
				systemEmailFromName: systemEmail.systemEmailFromName,
				systemEmailReplyAddress: systemEmail.systemEmailReplyAddress,
				systemEmailFromAddress: systemEmail.systemEmailFromAddress,
				isCSPortalConfirmationEmail: systemEmail.isCSPortalConfirmationEmail,
				isPublicPortalConfirmationEmail: systemEmail.isPublicPortalConfirmationEmail,
				brandId: systemEmail.brandId,
				brandName: systemEmail.brandName,
				createdBy: systemEmail.createdBy,
				createdByName: systemEmail.createdByName,
				createdByDate: format(new Date(systemEmail.createdByDate), DATE_FORMAT),
				editedBy: systemEmail.editedBy,
				editedByName: systemEmail.editedByName,
				lastEditedDate: systemEmail.lastEditedDate,
				EmailTypeId: systemEmail.EmailTypeId
			})),
			emailTypes: data.emailTypes.map(emailType => ({
				...emailType,
				emailTypeId: emailType.emailTypeId,
				emailTypeDescription: emailType.emailTypeDescription,
				isTranslatable: emailType.isTranslatable
			})),
			emailPlaceholders: data.emailPlaceholders.map(placeholder => ({
				...placeholder,
				mergeFieldId: placeholder.mergeFieldId,
				emailTypeId: placeholder.emailTypeId,
				placeholderName: placeholder.placeholderName
			}))
		}
	})))
}
export const getSystemFromEmails = () => {
	return configApi.get('api/systemEmails?$expand=SystemEmails($select=SystemEmailFromAddress,SystemEmailId,SystemEmailName,EmailTypeId)').then((
		{ data }) => data.SystemEmails.map(x => ({ email: x.SystemEmailFromAddress, id: x.SystemEmailId, name: x.SystemEmailName, emailTypeId: x.EmailTypeId })))
}
export const getSenderEmailAddresses = () => {
	return configApi.get('/api/senderEmails').then(({ data: { senderEmails } }) => JSON.parse(JSON.stringify(({
		data: senderEmails.map(senderEmail => ({
			...senderEmail,
			senderEmailAddressId: senderEmail.emailID,
			fromDisplayName: senderEmail.fromDisplay,
			fromAddress: senderEmail.fromAddress,
			replyAddress: senderEmail.replyAddress,
			spfValid: senderEmail.spfValid,
			createdDate: format(new Date(senderEmail.dateAdded), DATE_FORMAT)
		}))
	}))))
}

export const createSystemEmail = systemEmail => {
	return configApi.post('/api/SystemEmails', systemEmail)
}

export const updateSystemEmail = systemEmail => {
	return configApi.put('/api/SystemEmails', systemEmail)
}

export const sendTestSystemEmail = sendTestEmailRequest => {
	return configApi.post('api/SystemEmails/SendTestEmail', sendTestEmailRequest)
}

export const deleteSystemEmailTranslation = systemEmailTranslationId => configApi.delete(`api/SystemEmails/Translations/Delete/${systemEmailTranslationId}`)

export const upsertSystemEmailTranslation = systemEmailTranslation => configApi.post('api/SystemEmails/Translations/Upsert', systemEmailTranslation)
