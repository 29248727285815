<template>
	<validation-observer
		#default="{ handleSubmit, reset }"
	>
		<Modal
			width="650px"
		>
			<template #modal-title>
				{{ modalTitle }}
				<v-spacer />
				<IconButton
					:test-id="fieldId('close_button')"
					@click="close(reset)"
				>
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<Dropdown
						v-model="metaData.metaDataKeyId"
						:items="metaDataKeyItems"
						:disabled="isEdit"
						:test-id="fieldId('metadata_key')"
						label="Metadata Key"
						rules="required"
					/>
					<Dropdown
						v-if="displayDropdown"
						v-model="metaData.metaDataValue"
						:items="metaDataDropdownItems"
						:test-id="fieldId('metadata_value')"
						label="Metadata Value"
						rules="required"
					/>
					<TextField
						v-else
						v-model="metaData.metaDataValue"
						:test-id="fieldId('metadata_value')"
						label="Metadata Value"
						:rules="{required: true, max: 255}"
					/>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton
					:test-id="fieldId('metadata_cancel_button')"
					@click="close"
				>
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					:test-id="fieldId('metadata_submit_button')"
					@click="handleSubmit(submit)"
				>
					{{ !isEdit ? 'Add' : 'Save' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</validation-observer>
</template>
<script>
import Modal from '../../../../shared/components/modal.vue'
import IconButton from '../../../../shared/components/icon-button.vue'
import Dropdown from '../../../../shared/components/dropdown.vue'
import TextField from '../../../../shared/components/text-field.vue'
import PrimaryActionButton from '../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../shared/components/secondary-action-button.vue'
import { getExternalMetaData } from '../../../../shared/utils/api/external-meta-data.js'
import { showSnackbar } from '../../../../shared/state/snackbar.js'
export default {
	components: {
		Modal,
		IconButton,
		Dropdown,
		PrimaryActionButton,
		SecondaryActionButton,
		TextField
	},
	props: {
		moduleType: {
			type: String,
			required: true
		},
		metaDataToEdit: Object,
		assignedMetaData: Array,
		componentTypeId: Number
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			metaDataKeys: [],
			metaData: JSON.parse(JSON.stringify(this.metaDataToEdit || {
				metaDataKeyId: null,
				metaDataValue: ''
			}))
		}
	},
	computed: {
		isEdit () {
			return !!this.metaDataToEdit
		},
		modalTitle () {
			return `${this.isEdit ? 'Edit' : 'Add'} ${this.moduleType} Metadata`
		},
		metaDataDropdownItems () {
			return this.metaDataKeyItems.find(({ value }) => value === this.metaData.metaDataKeyId)?.dropdownItems.map(dropdown => {
				return dropdown.value
			}).filter(dropdown => !this.assignedMetaData.find(({ metaDataKeyId, metaDataValue }) => metaDataKeyId === this.metaData.metaDataKeyId && metaDataValue === dropdown))
		},
		displayDropdown () {
			return !!this.metaDataKeyItems.find(({ value }) => value === this.metaData.metaDataKeyId)?.dropdownItems.length >= 1
		},
		metaDataKeyItems () {
			return this.metaDataKeys.map(metaDataKey => ({
				value: metaDataKey.id,
				text: metaDataKey.name,
				associatedComponentTypes: metaDataKey.associatedComponentTypes,
				dropdownItems: metaDataKey.dropDownValues
			})).filter(({ associatedComponentTypes }) => associatedComponentTypes.includes(this.componentTypeId))
		}
	},
	async created () {
		const { externalMetaData } = await getExternalMetaData()
		this.metaDataKeys = externalMetaData
	},
	methods: {
		fieldId (field) {
			return `${this.moduleType.toLowerCase().replaceAll(' ', '_')}_${field}`
		},
		close () {
			this.$emit('close')
		},
		updateMetadata (assignedMetaData) {
			this.$emit('update:metadata', assignedMetaData)
		},
		submit () {
			const duplicateKeyAndValue = this.assignedMetaData.find(
				({ metaDataKeyId, metaDataValue }) => metaDataKeyId === this.metaData.metaDataKeyId && metaDataValue === this.metaData.metaDataValue
			)
			if (!this.isEdit) {
				if (duplicateKeyAndValue) {
					showSnackbar({ color: 'red', text: 'Combination of this metadata key and value already exists' })
					return
				} else {
					this.updateMetadata([
						...(this.assignedMetaData),
						{
							...this.metaData,
							metaDataKeyName: this.metaDataKeyItems.find(({ value }) => value === this.metaData.metaDataKeyId).text
						}
					])
				}
			} else {
				const existingAssignedMetaData = this.assignedMetaData
				const index = existingAssignedMetaData.findIndex(
					({ metaDataKeyId, metaDataValue }) => metaDataKeyId === this.metaDataToEdit.metaDataKeyId && metaDataValue === this.metaDataToEdit.metaDataValue)
				this.metaData.metaDataKeyName = this.metaDataKeyItems.find(({ value }) => value === this.metaData.metaDataKeyId).text
				const editedMetadata = this.metaData
				editedMetadata.metaDataKeyName = this.metaDataKeyItems.find(({ value }) => value === this.metaData.metaDataKeyId).text
				this.$set(existingAssignedMetaData, index, editedMetadata)
				this.updateMetadata(existingAssignedMetaData)
			}
			this.$emit('close')
		}
	}
}
</script>
