<template>
	<div>
		<template v-for="stepData in steps">
			<slot
				v-if="stepData.slot === step"
				:name="stepData.stepNumber"
			/>
		</template>
	</div>
</template>

<script>
export default {
	props: {
		step: {
			type: Number,
			required: true
		},
		steps: {
			type: Array,
			required: true
		}
	}
}
</script>
