<template>
	<Modal width="500px">
		<template #modal-title>
			Are you sure?
		</template>
		<template #modal-content>
			You have made changes that have not been saved. Are you sure you want to proceed?
		</template>
		<template #modal-footer>
			<SecondaryActionButton @click="$emit('cancel')">
				Cancel
			</SecondaryActionButton>
			<PrimaryActionButton @click="proceed">
				Proceed
			</PrimaryActionButton>
		</template>
	</Modal>
</template>

<script>
import SecondaryActionButton from './secondary-action-button.vue'
import PrimaryActionButton from './primary-action-button.vue'
import Modal from './modal.vue'

export default {
	name: 'unsaved-changes-modal',
	components: { Modal, PrimaryActionButton, SecondaryActionButton },
	props: {
		to: {
			type: String,
			required: false
		},
		next: {
			type: Function,
			required: true
		}
	},
	methods: {
		proceed () {
			this.$emit('proceed')
			this.next()
		}
	}
}
</script>
