<template>
	<validation-observer
		ref="validationObserver"
		#default="props"
		v-bind="$props"
		class="d-flex flex-column flex-grow-1"
	>
		<slot
			v-bind="props"
			:handle-submit="callback => handleSubmit(props, callback)"
		/>
	</validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { registerValidationForm, unregisterValidationForm } from '../state/validation-observers.js'
import { showSnackbar } from '../state/snackbar.js'

export default {
	name: 'validation-form',
	extends: ValidationObserver,
	mounted () {
		registerValidationForm(this.$refs.validationObserver)
		this.$watch(() => this.$refs.validationObserver.flags.changed, newVal => {
			this.$emit('form-changed', newVal)
		})
	},
	beforeDestroy () {
		unregisterValidationForm(this.$refs.validationObserver._uid)
	},
	methods: {
		handleSubmit ({ handleSubmit, reset }, callback) {
			handleSubmit(async () => {
				reset()
				callback()
			}).then(() => {
				const errors = this.$refs.validationObserver?.errors
				if (errors) {
					window.veelidate = this.$refs.validationObserver
					const errorFields = Object.keys(errors).filter(field => !field.includes('_vee')).map(key => { return { field: key.replace(' *', ''), errors: errors[key] } }).filter(field => field.errors.length > 0)
					if (errorFields.length > 0) {
						const text = 'Form invalid - ' + errorFields.map(field => `${field.field} - ${field.errors.join(',')}`).join(', ')
						showSnackbar({
							text: text,
							color: 'error'
						})
					}
				}
			})
		}
	}
}
</script>
